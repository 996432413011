import { Paper, Stack } from "@mui/material";
import { Parameters } from "../parameters/Parameters";
import { PeriodSelector } from "../periodselector";
import { Statistics } from "../statistics/Statistics";

export const Content = ({
  commutingDays,
  setCommutingDays,
  distance,
  setDistance,
  consumption,
  setConsumption,
  pricePerLiter,
  setPricePerLiter,
}) => {
  return (
    <Stack spacing={2}>
      <Paper sx={{ p: "15px" }}>
        <PeriodSelector
          commutingDays={commutingDays}
          setCommutingDays={setCommutingDays}
        />
      </Paper>

      <Paper sx={{ p: "15px" }}>
        <Parameters
          distance={distance}
          setDistance={setDistance}
          consumption={consumption}
          setConsumption={setConsumption}
          pricePerLiter={pricePerLiter}
          setPricePerLiter={setPricePerLiter}
        />
      </Paper>

      <Paper sx={{ p: "15px" }}>
        <Statistics
          commutingDays={commutingDays}
          distance={distance}
          consumption={consumption}
          pricePerLiter={pricePerLiter}
        />
      </Paper>
    </Stack>
  );
};
