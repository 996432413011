import { Stack, Typography } from "@mui/material";
import { ReactComponent as PekoIcon } from "../../assets/images/favicons/peko_favicon.svg";

export const Header = ({ title, children }) => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      sx={{ mt: 3 }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ display: "flex", gap: "10px", textAlign: "center" }}
      >
        <PekoIcon width={40} height={40} /> {title}
      </Typography>
      <Stack direction="row" justifyContent="right">
        {children}
      </Stack>
    </Stack>
  );
};
