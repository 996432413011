import { useTheme } from "@mui/system";
import { useContext } from "react";
import { ColorModeContext } from "./ColorModeContext";
import { IconButton, SvgIcon } from "@mui/material";
import { ReactComponent as SunIcon } from "../../assets/fontawesome/svgs/duotone/sun.svg";
import { ReactComponent as MoonIcon } from "../../assets/fontawesome/svgs/duotone/moon-stars.svg";

export const ColorModeToggle = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <IconButton
      sx={{ m: "auto auto auto 1", height: "50px", width: "50px" }}
      onClick={colorMode.toggleColorMode}
      color="inherit"
    >
      {theme.palette.mode === "dark" ? (
        <SvgIcon component={SunIcon} inheritViewBox />
      ) : (
        <SvgIcon component={MoonIcon} inheritViewBox />
      )}
    </IconButton>
  );
};
