import { Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { getRoundedValue } from "../../utils/maths/getRoundedValue";
import { getCostPerDistance } from "../../utils/getCostPerDistance";
import { useTranslation } from "react-i18next";

import { ReactComponent as CoinsIcon } from "../../assets/fontawesome/svgs/duotone/coins.svg";
import { ReactComponent as SmogIcon } from "../../assets/fontawesome/svgs/duotone/smog.svg";

// Source: https://www.fleetnews.co.uk/costs/carbon-footprint-calculator/
const FUEL_MULTIPLIER = {
  diesel: 2.62,
  petrol: 2.39,
};

export const Statistics = ({
  distance,
  consumption,
  pricePerLiter,
  commutingDays = [],
}) => {
  const { t } = useTranslation("result");
  let costPerDistance = getCostPerDistance(
    distance,
    consumption,
    pricePerLiter
  );
  let costPerRoundTrip = costPerDistance * 2;
  let costPerWeek = commutingDays.length * costPerRoundTrip;

  let co2PerDistance = (consumption / 100) * distance * FUEL_MULTIPLIER.petrol;
  let co2PerRoundTrip = co2PerDistance * 2;
  let co2PerWeek = commutingDays.length * co2PerRoundTrip;

  let statistics = [
    [
      t("costPerTrip"),
      getRoundedValue(costPerDistance, 2).toFixed(2) + " €",
      getRoundedValue(co2PerDistance, 2).toFixed(2) + " kg",
    ],
    [
      t("costPerRoundTrip"),
      getRoundedValue(costPerRoundTrip, 2).toFixed(2) + " €",
      getRoundedValue(co2PerRoundTrip, 2).toFixed(2) + " kg",
    ],
    [
      t("costPerWeek"),
      getRoundedValue(costPerWeek, 2).toFixed(2) + " €",
      getRoundedValue(co2PerWeek, 2).toFixed(2) + " kg",
    ],
    [
      t("costPerMonth"),
      getRoundedValue(costPerWeek * 4, 2).toFixed(2) + " €",
      getRoundedValue(co2PerWeek * 4, 2).toFixed(2) + " kg",
    ],
  ];

  return (
    <Stack>
      <Typography variant="h5">{t("title")}</Typography>

      <Grid
        container
        spacing={2}
        sx={{ mt: "0px", borderBottom: "thin solid black" }}
      >
        <Grid item xs={6} sx={{ textAlign: "left" }}></Grid>
        <Grid item xs={3} sx={{ textAlign: "right" }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ mb: "5pt" }}
          >
            <Typography>{t("costs")}</Typography>
            <SvgIcon component={CoinsIcon} inheritViewBox />
          </Stack>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "right" }}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Typography>{t("co2")}</Typography>
            <SvgIcon component={SmogIcon} inheritViewBox />
          </Stack>
        </Grid>
      </Grid>
      {statistics.map((set) => (
        <Grid container spacing={2} key={JSON.stringify(set[1])}>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            {set[0]}:
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            {set[1]}
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            {set[2]}
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};
