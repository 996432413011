import {
  Box,
  Paper,
  InputAdornment,
  TextField,
  Typography,
  SvgIcon,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as RoadIcon } from "../../assets/fontawesome/svgs/duotone/road.svg";
import { ReactComponent as GasPumpIcon } from "../../assets/fontawesome/svgs/duotone/gas-pump.svg";
import { ReactComponent as MoneyBillWaveIcon } from "../../assets/fontawesome/svgs/duotone/money-bill-wave.svg";

export const Parameters = ({
  distance,
  setDistance,
  consumption,
  setConsumption,
  pricePerLiter,
  setPricePerLiter,
}) => {
  const { t } = useTranslation("parameter");

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t("title")}</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        justifyContent="space-between"
      >
        <TextField
          id="outlined-number"
          label={t("distance")}
          type="number"
          sx={{ textAlign: "right" }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ step: 0.1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: "15px" }}>
                <SvgIcon component={RoadIcon} inheritViewBox />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {t("distanceUnit")}
              </InputAdornment>
            ),
          }}
          defaultValue={distance}
          onChange={(event) => {
            setDistance(event.target.value);
          }}
        />

        <TextField
          id="outlined-number"
          label={t("consumption")}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={consumption}
          onChange={(event) => {
            setConsumption(event.target.value);
          }}
          inputProps={{ step: 0.1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: "15px" }}>
                <SvgIcon component={GasPumpIcon} inheritViewBox />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {t("consumptionUnit")}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="outlined-number"
          label={t("price")}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={pricePerLiter}
          onChange={(event) => {
            setPricePerLiter(event.target.value);
          }}
          inputProps={{ step: 0.01 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: "15px" }}>
                <SvgIcon component={MoneyBillWaveIcon} inheritViewBox />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">{t("priceUnit")}</InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
