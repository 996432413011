import { WEEKDAYS } from "./weekdays";

export let DEFAULT_DISTANCE = 5.5;
export let DEFAULT_CONSUMPTION = 6.5;
export let DEFAULT_PRICE = 1.95;

export let DEFAULT_PERIOD_SELECTION = [
  WEEKDAYS.monday,
  WEEKDAYS.tuesday,
  WEEKDAYS.wednesday,
  WEEKDAYS.thursday,
  WEEKDAYS.friday,
];
