import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Container,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { WEEKDAYS } from "../../constants/weekdays";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PeriodSelector = ({ commutingDays, setCommutingDays }) => {
  const { t } = useTranslation("period");
  const theme = useTheme();
  const isGreaterSM = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange = (e) => {
    const { value } = e.target;

    if (commutingDays.includes(value)) {
      setCommutingDays(commutingDays.filter((v) => v !== value));
    } else {
      setCommutingDays([...commutingDays, value]);
    }
  };

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    setCommutingDays(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormGroup>
      <Typography variant="h5">{t("title")}</Typography>

      <Container>
        {!isGreaterSM && (
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="demo-multiple-name-label">
              {t("weekday")}
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={commutingDays}
              onChange={handleChanges}
              input={<OutlinedInput label={t("weekday")} />}
              MenuProps={MenuProps}
            >
              {Object.values(WEEKDAYS).map((name) => (
                <MenuItem key={name} value={name}>
                  {t(name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isGreaterSM && (
          <Grid
            container
            spacing={{ md: 3 }}
            columns={{ sm: 12, md: 7, lg: 7 }}
          >
            {Object.values(WEEKDAYS).map((day) => {
              return (
                <Grid
                  item
                  sm={3}
                  md={1}
                  lg={1}
                  key={day}
                  sx={{ textAlign: "left !important" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={commutingDays.includes(day)}
                        onChange={handleChange}
                        value={day}
                      />
                    }
                    label={t(day)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </FormGroup>
  );
};
