import { Button, Snackbar, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorageKeys";

import { useLocalStorage } from "../../hooks/useLocalStorage";

export const CookieBanner = ({ onAccept }) => {
  const { t } = useTranslation("cookies");
  const [cookiePreference, setCookiePreference] = useLocalStorage(
    LOCAL_STORAGE_KEYS.allowCookies,
    undefined
  );
  const [open, setOpen] = useState(cookiePreference === true ? false : true);

  return (
    <Snackbar
      open={open}
      message={
        <Stack>
          <Typography>{t("text")}</Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={4}>
            <Button
              onClick={() => {
                setCookiePreference(false);
                setOpen(false);
              }}
            >
              {t("deny")}
            </Button>
            <Button
              onClick={() => {
                setCookiePreference(true);
                onAccept();
                setOpen(false);
              }}
              variant="contained"
            >
              {t("accept")}
            </Button>
          </Stack>
        </Stack>
      }
    ></Snackbar>
  );
};
