/**
 * @param distance {number} Distance in kilometres
 * @param consumption {number} Liters consumed per 100 km
 * @param pricePerLiter {number} Price per liter
 * @return {number} Cost to drive distance
 */
export function getCostPerDistance(distance, consumption, pricePerLiter) {
  let pricePerMeter = (consumption * pricePerLiter) / 100;
  return distance * pricePerMeter;
}
