import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import deTranslation from "../assets/localization/de/translation.json";
import enTranslation from "../assets/localization/en/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector) // detect user language; learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      de: deTranslation,
      en: enTranslation,
    },
  });
