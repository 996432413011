import { createTheme } from "@mui/material";

function isLightMode(mode) {
  return mode === "light";
}

export const createThemeForMode = (mode) =>
  createTheme({
    palette: {
      mode,
      background: {
        paper: isLightMode(mode) ? "#fefefe" : "#313431",
        default: isLightMode(mode) ? "#f1f5f9" : "#222622",
      },
      text: {
        primary: isLightMode(mode) ? "#003f71" : "#EDEFED",
        secondary: isLightMode(mode) ? "#3f5178" : "#888B88",
        //disabled: "#55555",
      },
    },
  });
