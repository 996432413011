import { Alert, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import pck from "../../../package.json";

export const Footer = () => {
  const { t } = useTranslation("main");
  return (
    <Alert severity="warning">
      <Typography color="red">
        <b color="red">{t("disclaimer")}</b>
      </Typography>
      <Typography>V {pck.version}</Typography>
    </Alert>
  );
};
