import { useState, useMemo, useEffect, useCallback } from "react";
import "./App.css";
import { Container, Stack, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAnalytics } from "firebase/analytics";
import { useTranslation } from "react-i18next";
import "./utils/i18n";
import { ColorModeContext } from "./compositions/colormodetoggle/ColorModeContext";
import { ColorModeToggle } from "./compositions/colormodetoggle/ColorModeToggle";
import { LanguageToggle } from "./compositions/languagetoggle";
import {
  DEFAULT_CONSUMPTION,
  DEFAULT_DISTANCE,
  DEFAULT_PERIOD_SELECTION,
  DEFAULT_PRICE,
} from "./constants/defaultSettings";
import { Content } from "./compositions/layout/Content";
import { Header } from "./compositions/layout/Header";
import { Footer } from "./compositions/layout/Footer";
import { CookieBanner } from "./compositions/cookiebanner";
import { LOCAL_STORAGE_KEYS } from "./constants/localStorageKeys";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { createThemeForMode } from "./constants/theme";

function App({ firebaseApp }) {
  const { t } = useTranslation(["main", "period"]);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [storedColorMode, setColorMode] = useLocalStorage(
    LOCAL_STORAGE_KEYS.colorMode
  );
  const [mode, setMode] = useState(
    storedColorMode ? storedColorMode : prefersDarkMode ? "dark" : "light"
  );

  const [allowCookies] = useLocalStorage(LOCAL_STORAGE_KEYS.allowCookies);
  const [commutingDays, setCommutingDays] = useState(DEFAULT_PERIOD_SELECTION);
  const [distance, setDistance] = useState(DEFAULT_DISTANCE);
  const [consumption, setConsumption] = useState(DEFAULT_CONSUMPTION);
  const [pricePerLiter, setPricePerLiter] = useState(DEFAULT_PRICE);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((old) => (old === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createThemeForMode(mode), [mode]);

  useEffect(() => {
    setColorMode(mode);
  }, [mode, setColorMode]);

  const startAnalytics = useCallback(() => {
    const analytics = getAnalytics(firebaseApp);
  }, [firebaseApp]);

  useEffect(() => {
    // load cookies
    if (allowCookies) {
      startAnalytics();
    }
  }, [allowCookies, startAnalytics]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          className="App"
          sx={{ bgcolor: "background.default", color: "text.primary" }}
        >
          <header className="App-header">
            <Container>
              <Stack spacing={1} sx={{ m: "auto" }}>
                <Header title={t("title")}>
                  <LanguageToggle />
                  <ColorModeToggle />
                </Header>
                <Content
                  distance={distance}
                  setDistance={setDistance}
                  commutingDays={commutingDays}
                  setCommutingDays={setCommutingDays}
                  consumption={consumption}
                  setConsumption={setConsumption}
                  pricePerLiter={pricePerLiter}
                  setPricePerLiter={setPricePerLiter}
                />
                <Footer />
              </Stack>
            </Container>
          </header>
        </Box>
        <CookieBanner onAccept={() => startAnalytics()} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
