import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

export const LanguageToggle = () => {
  const { i18n } = useTranslation(["main", "period"]);
  return (
    <Button
      onClick={() =>
        i18n.language === "de"
          ? i18n.changeLanguage("en")
          : i18n.changeLanguage("de")
      }
    >
      {i18n.language === "de" ? "EN" : "DE"}
    </Button>
  );
};
